import React from 'react';
import PropTypes from 'prop-types';
import { filters, filtersTypes } from './filtersList';
import Filter from './Filter';
import DateFilter from './DateFilter';
import { buildQueryString } from './filters_serializer';

class FilterBox extends React.Component {
  constructor(props) {
    super(props);
    const filtersState = {};
    filters.forEach((filter) => {
      if (filter.format === 'multiple') {
        filtersState[filter.type] = [];
      } else if (filter.format === 'single') {
        filtersState[filter.type] = '';
      } else {
        filtersState[filter.type] = null;
      }
    });
    this.state = {
      filtersOpen: false,
      clickedFilter: '',
      keyword: props.keyword || '',
      selectedFilters: props.selectedFilters || [],
      ...filtersState,
    };
  }

  componentDidMount() {
    // check that the user is signed-in to be able to exclude some filters if they're not
    const element = document.getElementById('filters-container');
    if (element) {
      const userSignedInValue = element.getAttribute('data-user-signed-in');
      this.setState({
        userSignedIn: userSignedInValue === 'true',
      });
    }
  }

  handleSearchClick = () => {
    const { selectedFilters, keyword } = this.state;
    const queryString = buildQueryString(selectedFilters, keyword);
    if (queryString.length > 0) {
      window.location.replace(
        `${window.location.origin}/videos/replay/1?${queryString}`
      );
    } else {
      window.location.replace(`${window.location.origin}/videos/replay/1`);
    }
  };

  handleParentFilterClick = (filter) => {
    const newValue =
      this.state.clickedFilter === filter.name ? '' : filter.name;
    this.setState({
      clickedFilter: newValue,
    });
  };

  handleInputChange = (event) => {
    this.setState({
      keyword: event.target.value,
    });
  };

  removeKeyword = () => {
    this.setState({
      keyword: ''
    })
  }

  handleResetFilters = () => {
    // Reset all filters and keyword
    this.setState({
      filtersOpen: false,
      clickedFilter: '',
      keyword: '',
      selectedFilters: [],
    });

    window.location.replace(`${window.location.origin}/videos/replay/1`);
  }

  toggleFromSelected = (filter) => {
    let newFilters = this.state.selectedFilters;
    const foundFilter = this.state.selectedFilters.find(
      (el) => el.name === filter.name
    );
    // Si le filtre est present
    if (foundFilter) {
      // retirer le filtre
      const foundFilterIndex = this.state.selectedFilters.indexOf(foundFilter);
      newFilters.splice(foundFilterIndex, 1);
      // Sinon (filtre absent)
    } else {
      // si le type est single
      if (filtersTypes[filter.type] === 'single') {
        // Si un filtre du meme type est présent
        const sameTypeFilter = this.state.selectedFilters.find(
          (el) => el.type === filter.type
        );
        if (sameTypeFilter) {
          // retirer le filtre
          const sameTypeFilterIndex = this.state.selectedFilters.indexOf(
            sameTypeFilter
          );
          newFilters.splice(sameTypeFilterIndex, 1);
        }
      }
      // Ajouter le filtre
      newFilters.push(filter);
    }
    this.setState({
      selectedFilters: newFilters,
    });
  };

  toggleOpening = () => {
    this.setState({
      filtersOpen: !this.state.filtersOpen,
    });
  };

  render() {
    let recapClasses =
      'col-12 text-center d-flex justify-content-center align-items-center pb-4 filter-line';
    if (this.state.selectedFilters.length >= 1 || this.state.keyword.length > 0) {
      recapClasses += ' filter-recap';
    }
    return (
      <React.Fragment>
        <div className={recapClasses}>
          {/* <div className='h-2r white-bg text-jazzberry tag tag--primary d-flex align-items-center filter w-0'>
            <p className='m-0'>&nbsp;</p>
          </div> */}
          {(this.state.selectedFilters.length >= 1 || this.state.keyword.length > 0) && (
            <p className='text-jazzberry m-0 mb-2 mx-2'>Votre sélection :</p>
          )}
          {this.state.selectedFilters.map((filter, index) => {
            return (
              <Filter
                key={`selected-${filter.name}`}
                {...filter}
                filter={filter}
                onSelect={this.toggleFromSelected}
                selected
              />
            );
          })}
          {this.state.keyword.length > 0 && (
            <Filter
              key={`selected-keyword`}
              name={this.state.keyword}
              type="keyword"
              onSelect={this.removeKeyword}
              selected
            />
          )}
        </div>
        {(this.state.selectedFilters.length >= 1 || this.state.keyword.length > 0) && (
          <div className='col-12 text-center d-flex flex-row justify-content-center mb-2 no-height'>
            <div
              className='h-2r mr-2 mb-2 purple-bg white-font tag tag--primary p-2 px-3 d-flex align-items-center search-button'
              onClick={this.handleSearchClick}
            >
              <p className='m-0 mr-2'>Rechercher</p>
              <i className='fas fa-search'></i>
            </div>

            <div className='h-2r mr-2 mb-2 purple-bg white-font tag tag--secondary p-2 px-3 d-flex align-items-center search-button'
                 onClick={this.handleResetFilters}>
              <p className='m-0 mr-2'>Réinitialiser</p>
              <i className='fas fa-undo'></i>
            </div>
          </div>
        )}
        <div className='col-12 text-center d-flex flex-row justify-content-center filter-line'>
          <div
            className={`h-2r mr-2 mb-2 tag tag--primary p-2 px-3 d-flex align-items-center filter visible ${
              this.state.filtersOpen ? 'clicked-parent' : ''
            }`}
            onClick={this.toggleOpening}
          >
            {/* <i
              className={
                this.state.filtersOpen ? 'fas fa-times' : 'fas fa-ellipsis-h'
              }
            ></i> */}
            <i className="fas fa-plus mr-2" style={{transform: `rotate(${this.state.filtersOpen ? '45deg' : '0deg'}`, transition: 'transform 0.3s ease-in-out'}}></i>
            <p className='m-0'>Filtres</p>
          </div>
          <input
            id="filter-input"
            type='text'
            name='keyword'
            placeholder={`${'mot clé'.toUpperCase()} (facultatif)`}
            onChange={this.handleInputChange}
            value={this.state.keyword.toUpperCase()}
            className='h-2r mr-2 mb-2 tag tag--primary px-2 d-flex align-items-center'
          />
        </div>
        <div className='col-12 text-center d-flex justify-content-center align-items-center'>
          <a href="https://vimeo.com/472126488" target="_blank" className="text-jazzberry m-0 my-2">
            <i class="fa-solid fa-circle-info mr-2"></i> <i>Voir la video explicative</i>
          </a>
        </div>
        {this.state.filtersOpen && (
          <div>
            <div className='col-12 text-center d-flex justify-content-center align-items-center mt-3 filter-line'>
              {/* <p class="text-jazzberry m-0 mb-2 mr-2  d-md-block d-none">Filtres :</p> */}
              {filters.map((filter) => {
                if (filter.type === 'date') {
                  return (
                    <DateFilter
                      key={filter.name}
                      onSelect={this.toggleFromSelected}
                      onClick={this.handleParentFilterClick}
                      clicked={this.state.clickedFilter === filter.name}
                    />
                  );
                } else if (filtersTypes[filter.type] === 'boolean' && this.state.userSignedIn) {
                    return (
                      <Filter
                        key={filter.name}
                        {...filter}
                        parent
                        filter={filter}
                        onSelect={this.toggleFromSelected}
                        clicked={this.state.clickedFilter === filter.name}
                        selected={
                          typeof this.state.selectedFilters.find(
                            (f) => f.name === filter.name
                          ) != 'undefined'
                        }
                      />
                    );
                } else if (filtersTypes[filter.type] === 'boolean' && !this.state.userSignedIn) {
                  // Exclude favorites, commented, and seen filters when the user is not signed in
                  return null;
                }
                else {
                  return (
                    <Filter
                      key={filter.name}
                      {...filter}
                      filter={filter}
                      parent
                      onClick={this.handleParentFilterClick}
                      children={filter.children}
                      clicked={this.state.clickedFilter === filter.name}
                    />
                  );
                }
              })}
            </div>
          </div>
        )}
        {this.state.filtersOpen && (
          <div className='col-12 text-center d-flex justify-content-center mb-3 filter-line'>
            {filters.map((filter) => {
              if (
                this.state.clickedFilter === filter.name &&
                filter.children &&
                filter.children.length >= 1
              ) {
                return filter.children.map((child, index) => {
                  return (
                    <Filter
                      key={child.name}
                      // name={child.name}
                      // type={child.type}
                      index={index + 1}
                      filter={child}
                      // color={child.color}
                      // children={child.children}
                      {...child}
                      onSelect={this.toggleFromSelected}
                      selected={
                        typeof this.state.selectedFilters.find(
                          (f) => f.name === child.name
                        ) != 'undefined'
                      }
                    />
                  );
                });
              }
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default FilterBox;
