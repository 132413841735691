const buildQueryString = (selectedFilters, keyword) => {
  let queryStr = serializeFilters(selectedFilters);
  if (keyword) {
    if (queryStr.length > 0) {
      queryStr += '&keyword=' + encodeURIComponent(keyword);
    } else {
      queryStr = 'keyword=' + encodeURIComponent(keyword);
    }
  }
  return queryStr;
};

const serializeFilters = (selectedFilters) => {
  let str = [];
  let filtersObj = {};
  const filters = selectedFilters;
  filters.forEach((filter) => {
    const filterType = filter.type;
    if (filtersObj.hasOwnProperty(filterType)) {
      // filter.delete('type');
      filtersObj[filterType].push(filter);
    } else {
      filtersObj[filterType] = [filter];
    }
  });
  for (let key in filtersObj) {
    if (filtersObj.hasOwnProperty(key)) {
      const serializedFilters = serializeFiltersForType(filtersObj[key]);
      str.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(serializedFilters)
      );
      // console.log(key, filtersObj[key]);
    }
  }
  return str.join('&');
};

const serializeFiltersForType = (filtersForType) => {
  let str = [];
  filtersForType.forEach((filter) => {
    str.push(serializeFilter(filter));
  });
  return str.join('_WITH_');
};

const serializeFilter = (filter) => {
  let str = [];
  for (let key in filter)
    if (filter.hasOwnProperty(key)) {
      str.push(
        encodeURIComponent(key) + '_IS_' + encodeURIComponent(filter[key])
      );
    }
  return str.join('_AND_');
};

export { buildQueryString };
