import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["course", "form"]

  displayForm() {
    this.courseTarget.classList.add("d-none")
    this.formTarget.classList.remove("d-none")
  }
}
