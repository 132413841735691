import React from "react"
import PropTypes from "prop-types"
class FilterFields extends React.Component {
  render () {
    return (
      <React.Fragment>
      </React.Fragment>
    );
  }
}

export default FilterFields
