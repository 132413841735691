import Swal from 'sweetalert2';

const customSwalCSS = {
  closeButton: 'btn btn-info px-3 rounded-pill',
  confirmButton: 'btn btn-primary px-3 rounded-pill',
  cancelButton: 'btn btn-danger px-3 rounded-pill',
};

export const stripeAppearance = {
  // labels: 'floating',
  theme: 'stripe',
  variables: {
    // colorPrimary: '#ba44b4',
    // colorText: '#ba44b4',
    // colorDanger: '#fd1015',
    // colorBackground: '#f4a0db',
    // fontFamily: '"Montserrat", Helvetica, sans-serif',
    // spacingUnit: '3px',
    borderRadius: '23px',
    // fontSizeBase: '1rem',
    // colorIconCardError: '#fd1015',
    // colorTextPlaceholder: '#ba44b4'
  }
};

export const hide = (element) => {
  element.classList.add('d-none');
}

export const reveal = (element) => {
  element.classList.remove('d-none');
}

export const displayErrors = (errors) => {
  Swal.fire({
    title: 'Attention',
    icon: 'warning',
    html: errors.join('<br>'),
    buttonsStyling: false,
    customClass: customSwalCSS,
  });
}
