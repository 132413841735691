import { Controller } from '@hotwired/stimulus'
import Popover from 'bootstrap/js/dist/popover';

export default class extends Controller {
  connect() {
    new Popover(this.element, {
      trigger: 'hover',
    })
  }
}
