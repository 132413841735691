import { Controller } from "@hotwired/stimulus";
import { bootstrap } from "bootstrap"

export default class extends Controller {

  connect() {
    if (this.element.dataset.showModal === "true") {
      this.show();
    }
  }

  show() {
    this.element.classList.add("show");
    this.element.style.display = "block";
    this.element.style.top = "20%";
  }

  close() {
    this.element.remove();
  }
}
