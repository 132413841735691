import { Controller } from "@hotwired/stimulus"
import { emailIsValid, removeRed, setRed } from "../utils/form";

const errorMessage = 'Email incorrect.';

export default class extends Controller {
  static outlets = ["purchase"];

  clearEmailMessage() {
    removeRed(this.element);
    this.purchaseOutlet.errorsValue = this.purchaseOutlet.errorsValue.filter(m => m !== errorMessage);
  }

  checkEmail() {
    const validEmail = emailIsValid(this.element.value);
    if (validEmail) {
      this.purchaseOutlet.emailValue = this.element.value;
    } else {
      this.purchaseOutlet.errorsValue = [...this.purchaseOutlet.errorsValue, errorMessage];
      setRed(this.element);
    };
  }
}
