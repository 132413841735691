import { Controller } from "@hotwired/stimulus"
import _ from "lodash";
import { displayErrors, hide, reveal, stripeAppearance } from "../utils/display";

export default class extends Controller {
  static targets = [
    "toggler",
    "total",
    "conditionsCheck",
    "selectionFormElement",
    "userForm",
    "firstNameInput",
    "formValidation",
    "stripeForm",
    "cart",
    "cartItem",
    "cartUser",
    "cartPrice",
    "paymentElement",
    "stripeError",
    "validatePaymentButton",
    "productCard"
  ];

  static values = {
    intentUrl: String,
    redirectUrl: String,
    productId: String,
    videoId: Number,
    total: { type: Number, default: 0 },
    errors: Array,
    checkedConditions: { type: Boolean, default: false },
    firstName: String,
    email: String,
    password: String,
    passwordConfirmation: String,
    items: Array,
    userSignedIn: Boolean,
    selectedPlan: String
  };

  // Default state
  setCheckConditionsClass() {
    this.conditionsCheckTarget.classList.remove('transparent');
    if (!this.checkedConditionsValue) {
      this.conditionsCheckTarget.classList.add('transparent')
    }
  }

  setDefaultProduct() {
    const togglers = this.togglerTargets;
    // const defaultProduct = togglers.find(t => Boolean(t.dataset.checked));
    const defaultProduct = togglers.find(t => t.dataset.productId === this.selectedPlanValue);
    if (defaultProduct) {
      defaultProduct.dataset.checked = true;
      this.productIdValue = defaultProduct.dataset.productId;
      this.totalValue = defaultProduct.dataset.price;
    }
  }

  connect() {
    this.setDefaultProduct();
    this.setCheckConditionsClass();
    hide(this.cartTarget);
    hide(this.paymentElementTarget);
    if (typeof Stripe === 'undefined') {
      console.error("Stripe is not defined for purchase")}
    this.stripe = Stripe(process.env.STRIPE_PK);
  }

  // Values Callbacks
  errorsValueChanged(value, previousValue) {
    if (value.length > 0 && value.length > previousValue.length) {
      displayErrors(this.errorsValue)
    }
  }

  checkedConditionsValueChanged() {
    this.setCheckConditionsClass();
  }

  productIdValueChanged(value, previousValue) {
    const product = this.itemsValue.find((i) => i.id === value);
    if (product) {
      this.totalTarget.innerHTML = `${product.price}€`;
      this.cartPriceTarget.innerHTML = product.price;
      this.cartItemTarget.innerHTML = product.label;
    }
  }

  emailValueChanged(value, previousValue) {
    this.cartUserTarget.innerHTML = value;
  }

  changeSelection(e) {
    const {params : { productId, price }} = e;
    const togglers = this.togglerTargets;
    togglers.forEach(c => c.dataset.checked = false);
    togglers.find(t => t.dataset.productId === productId)
      .dataset.checked = true;
    this.productIdValue = productId;
    this.totalValue = price;
    this.productCardTargets.forEach(card => card.classList.remove("product-selected"))
    e.currentTarget.classList.add("product-selected")
  }

  changeFirstName() {
    this.firstNameValue = this.firstNameInputTarget.value;
  }

  checkConditions() {
    this.checkedConditionsValue = !this.checkedConditionsValue;
  }

  formNotComplete() {
    if (this.userSignedInValue) {
      switch (true) {
        case this.errorsValue.length > 0 :
          displayErrors(this.errorsValue);
          return true;
        case !this.checkedConditionsValue :
          displayErrors(['Vous devez accepter les CGU pour procéder au paiement.']);
          return true;
        case this.totalTarget.innerHTML === "" :
          displayErrors(['Vous devez sélectionner une option avant de procéder au paiement.'])
          return true;
        default:
          return false;
      }
    } else {
      switch (true) {
        case !this.hasFirstNameValue :
        case !this.hasPasswordValue :
        case !this.hasPasswordConfirmationValue :
        case !this.hasEmailValue :
          displayErrors(['Veuillez vérifier que les champs sont bien remplis.']);
          return true;
        case this.errorsValue.length > 0 :
          displayErrors(this.errorsValue);
          return true;
        case !this.checkedConditionsValue :
          displayErrors(['Vous devez accepter les CGU pour procéder au paiement.']);
          return true;
        default:
          return false;
      }
    }
  }

  hideSelectionForm() {
    this.selectionFormElementTargets.forEach(e => hide(e));
  }

  revealSelectionForm() {
    this.selectionFormElementTargets.forEach(e => reveal(e));
  }

  paymentProceed() {
    // this.disableValidatePaymentButton();
    // Valider les champs et les CGU
    if (this.formNotComplete()) { return; }
    // Cacher la selection, le user et les CGU
    this.hideSelectionForm()
    // Afficher un récap et un bouton refresh pour annuler
    reveal(this.cartTarget);
    reveal(this.paymentElementTarget);
    this.setupStripePayment();
  }

  setupStripePayment() {
    const user = { email: this.emailValue }
    if (!this.userSignedInValue) {
      user.first_name = this.firstNameValue;
      user.password = this.passwordValue;
      user.password_confirmation = this.passwordConfirmationValue;
    }

    fetch(this.intentUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: user,
        product_id: this.productIdValue,
        video_id: this.videoIdValue,
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        // display errors sent from backend here
        this.revealSelectionForm();
        hide(this.cartTarget);
        hide(this.paymentElementTarget);
        displayErrors([data.error]);
        return;
      }

      this.clientSecret = data.paymentIntent.client_secret;

      // custom appearance to match K Yoga branding
      const appearance = {
        theme: 'flat',
        variables: {
          colorPrimary: '#9E0059',
          colorText: '#484848',
          borderRadius:'12px'}
      }

      const options = {
        clientSecret: this.clientSecret,
        appearance: appearance,
        loader: 'always'
      };

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 5
      this.elements = this.stripe.elements(options);
      // Create and mount the Payment Element
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#payment-element');
    })
  }

  async submitPayment(e) {
    e.preventDefault();
    this.checkStatus()
    const {error} = await this.stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements: this.elements,
      confirmParams: {
        return_url: this.redirectUrlValue,
      },
    });
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      // this.stripeErrorTarget.textContent = error.message;
      displayErrors(['Une erreur est survenue lors du paiement.'])
    }
  }

  disableValidatePaymentButton() {
    this.validatePaymentButtonTarget.disabled = true;
  }

  // To disable the button when the payment is processing
  async checkStatus() {
    if (!this.clientSecret) {
      return;
    }

    const { paymentIntent } = await this.stripe.retrievePaymentIntent(this.clientSecret);

    if (paymentIntent.status === 'requires_payment_method') {
      console.log("processing...");
      this.disableValidatePaymentButton()
    }
  }
}
