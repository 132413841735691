import { Controller } from "@hotwired/stimulus"
import videojs from "video.js";

export default class extends Controller {

  connect() {
    const videoElement = this.element
    const options = {
      controls: true,
      fluid: true,
      aspectRatio: '16:9'
    }

    const player = videojs(videoElement, options)
  }
}
