export const emailIsValid = (emailString) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailString)
}

export const passwordIsValid = (passwordString) => {
  return passwordString.length >= 6
}

const clearFontClasses = (element) => {
  // element.classList.remove('text-jazzberry');
  element.classList.remove('red-font');
}

export const setRed = (element) => {
  clearFontClasses(element);
  element.classList.add('red-font');
}
export const removeRed = (element) => {
  clearFontClasses(element);
  // element.classList.add('text-jazzberry');
}
