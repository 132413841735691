import { Controller } from "@hotwired/stimulus"
import { displayErrors, hide, reveal } from "../utils/display";

export default class extends Controller {

  static targets = ["userForm", "cartSelectionForm", "proceedToPayment", "total"]

  connect() {
    hide(this.userFormTarget)
    hide(this.proceedToPaymentTarget)
  }

  revealUserForm() {
    if (this.totalTarget.innerHTML != "") {
      hide(this.cartSelectionFormTarget)
      reveal(this.userFormTarget)
      reveal(this.proceedToPaymentTarget)
    } else {
      displayErrors(['Vous devez sélectionner une option avant de procéder au paiement.'])
    }
  }
}
