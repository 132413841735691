import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.rows = this.element.querySelectorAll("tbody tr")
    this.rows.forEach(row => row.addEventListener("click", this.rowClick.bind(this)))
  }

  rowClick(event) {
    this.triggerClick(event)
  }

  triggerClick(event){
    const url = event.currentTarget.dataset.url
    if(!url) return;

    window.open(url, '_self')
  }
}
