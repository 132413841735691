import { Controller } from "@hotwired/stimulus"
import createConsumer from "../channels/consumer"

export default class extends Controller {

  static targets = ["banner", "navbar"]

  connect() {
    this.subscription = createConsumer.subscriptions.create("MeetingChannel", {
      received: data => {
        if (data.type === 'banner') {
          this.bannerTarget.innerHTML = data.html;
        }
        if (data.type === 'navbar') {
          this.navbarTarget.innerHTML = data.html;
        }
      }
    })
  }
}
