import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.checkInputValidity();
  }

  checkInputValidity() {
    if (this.inputTarget.classList.contains("is-valid") ||
        this.inputTarget.classList.contains("is-invalid")) {
      this.element.classList.add("show");
    }
  }
}
