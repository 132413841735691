import React from 'react';
import PropTypes from 'prop-types';

class FilterButtonInput extends React.Component {
  // constructor() {
  //   super(props);
  // }

  handleClick = () => {
    if (!this.props.clicked) {
      this.props.onClick();
    }
    this.props.onDateClick();
  };

  render() {
    let displayClasses =
      'mb-2 h-2r mr-2 tag tag--primary px-3 d-flex align-items-center filter';
    if (this.props.clicked) {
      displayClasses += ' clicked-parent';
    }
    return (
      <div className={displayClasses} onClick={this.handleClick}>
        <p className='m-0'>Date</p>
      </div>
    );
  }
}

export default FilterButtonInput;
