import { Controller } from "@hotwired/stimulus"
import { passwordIsValid, removeRed, setRed, toggleRed } from "../utils/form"

const errorsMessage = {
  password: "Le mot de passe doit contenir au moins 6 caractères.",
  confirmation: "La vérification n'est pas identique au mot de passe.",
}

export default class extends Controller {
  static outlets = ["purchase"];
  static targets = ["password", "passwordConfirmation"]

  clearPasswordMessage() {
    removeRed(this.passwordTarget)
    this.purchaseOutlet.errorsValue = this.purchaseOutlet.errorsValue.filter(m => m !== errorsMessage.password);
  }

  clearConfirmationMessage() {
    removeRed(this.passwordConfirmationTarget)
    this.purchaseOutlet.errorsValue = this.purchaseOutlet.errorsValue.filter(m => m !== errorsMessage.confirmation);
  }

  checkPassword() {
    const passwordValid = passwordIsValid(this.passwordTarget.value);
    if (passwordValid) {
      this.purchaseOutlet.passwordValue = this.passwordTarget.value;
    } else {
      setRed(this.passwordTarget);
      this.purchaseOutlet.errorsValue = [...this.purchaseOutlet.errorsValue, errorsMessage.password];
    }
  }

  checkConfirmation() {
    const passwordValue = this.passwordTarget.value;
    const passwordConfirmationValue = this.passwordConfirmationTarget.value;
    const confirmationOk = passwordConfirmationValue === passwordValue;
    if (confirmationOk) {
      this.purchaseOutlet.passwordConfirmationValue = passwordConfirmationValue;
    } else {
      setRed(this.passwordConfirmationTarget);
      this.purchaseOutlet.errorsValue = [...this.purchaseOutlet.errorsValue, errorsMessage.confirmation];
    }
    // Append error message if invalid
  }
}
