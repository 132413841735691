const filters = [
  {
    name: 'date',
    type: 'date',
  },
  {
    name: 'type yoga',
    type: 'yoga_type',
    children: [
      {
        name: 'kundalini',
        type: 'yoga_type',
      },
      {
        name: 'hatha',
        type: 'yoga_type',
      },
      {
        name: 'gravity',
        type: 'yoga_type',
      },
      {
        name: 'mix',
        type: 'yoga_type',
      },
    ],
  },
  {
    name: 'pratique',
    type: 'practice',
    children: [
      {
        name: 'cours',
        type: 'practice',
      },
      {
        name: 'méditation',
        type: 'practice',
      },
      {
        name: 'zoom',
        type: 'practice',
      },
      {
        name: 'réveil inspiré',
        type: 'practice',
      },
    ],
  },
  {
    name: 'durée',
    type: 'duration',
    children: [
      {
        name: '< 30min',
        type: 'duration',
        value: 1,
      },
      {
        name: '30min à 1h',
        type: 'duration',
        value: 2,
      },
      {
        name: '1h à 1h30',
        type: 'duration',
        value: 3,
      },
      {
        name: '1h30 à 2h',
        type: 'duration',
        value: 4,
      },
    ],
  },
  {
    name: 'chakras',
    type: 'chakras',
    children: [
      {
        chakraIndex: 1,
        name: 'identité',
        type: 'chakras',
        color: '#FF1716',
      },
      {
        chakraIndex: 2,
        name: 'creativité',
        type: 'chakras',
        color: '#FFBD58',
      },
      {
        chakraIndex: 3,
        name: 'action',
        type: 'chakras',
        color: '#FFDE59',
      },
      {
        chakraIndex: 4,
        name: 'amour',
        type: 'chakras',
        color: '#347933',
      },
      {
        chakraIndex: 5,
        name: 'communication',
        type: 'chakras',
        color: '#36B6FF',
      },
      {
        chakraIndex: 6,
        name: 'intuition',
        type: 'chakras',
        color: '#8C53FF',
      },
      {
        chakraIndex: 7,
        name: 'connexion',
        type: 'chakras',
        color: '#B944B4',
      },
      {
        chakraIndex: 8,
        name: 'protection',
        type: 'chakras',
        color: '#FFFFFF',
      },
    ],
  },
  {
    name: 'cadre',
    type: 'environment',
    children: [
      {
        name: 'studio',
        type: 'environment',
      },
      {
        name: 'pleine nature',
        type: 'environment',
      },
    ],
  },
  {
    name: 'déjà vu',
    type: 'seen',
  },
  {
    name: 'favorites',
    type: 'favorites',
  },
  {
    name: 'commentées',
    type: 'commented',
  },
];

const filtersTypes = {
  date: 'single',
  environment: 'single',
  practice: 'multiple',
  style: 'single',
  seen: 'boolean',
  duration: 'single',
  chakras: 'multiple',
  yoga_type: 'single',
  favorites: 'boolean',
  commented: 'boolean'
};

export { filters, filtersTypes };
