import React from 'react';
import PropTypes from 'prop-types';
class Filter extends React.Component {
  constructor(props) {
    super(props);
    // this.handleFilterClick = this.handleFilterClick.bind(this);
  }

  handleFilterClick = () => {
    if (this.props.onSelect) {
      if (!this.props.selected) {
        this.props.onSelect(this.props.filter);
      }
    } else {
      this.props.onClick && this.props.onClick(this.props.filter);
    }
  };

  handleRemoveClick = () => {
    if (this.props.selected) {
      this.props.onSelect && this.props.onSelect(this.props.filter);
    }
  };

  render() {
    let purpleClasses =
      'mb-2 h-2r mr-2 tag tag--primary px-3 d-flex align-items-center filter';
    let pinkClasses =
      'mb-2 h-2r mr-2 tag tag--secondary px-3 d-flex align-items-center filter';
    let displayClasses = pinkClasses;
    let iconClasses = 'far fa-times-circle filter-icon ml-2';
    let diskClasses = 'fas fa-circle mr-2';
    if (this.props.parent) {
      displayClasses = purpleClasses;
      if (this.props.clicked) {
        displayClasses += ' clicked-parent';
      }
    } else if (this.props.selected) {
      displayClasses = pinkClasses + ' selected-filter';
    } else if (this.props.clicked) {
      displayClasses += ' clicked-filter';
    }
    return (
      <div className={displayClasses}>
        {this.props.color && this.props.chakraIndex && (
          <div
            className="d-flex justify-content-center align-items-center m-0 chakra-bullet mr-1"
            style={{ backgroundColor: this.props.color, color: '#F9EBF5' }}
          >
            <p className="m-0">{this.props.chakraIndex}</p>
          </div>
          // <i className={diskClasses} style={{ color: this.props.color }}></i>
        )}
        <p className='m-0' onClick={this.handleFilterClick}>
          {this.props.name}
        </p>
        {this.props.selected && (
          <i className={iconClasses} onClick={this.handleRemoveClick}></i>
        )}
      </div>
    );
  }
}

Filter.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  clicked: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};
export default Filter;
