import React from 'react';
import PropTypes from 'prop-types';
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import DatePicker from 'react-datepicker';
import FilterButtonInput from './FilterButtonInput';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

class DateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
    };
  }

  setSelectedDate = (date) => {
    this.setState({
      selectedDate: date,
    });
    if (this.props.onSelect) {
      this.props.onSelect({
        type: 'date',
        name: date.toLocaleDateString(),
      });
    }
    this.handleFilterClick();
  };

  handleFilterClick = () => {
    this.props.onClick && this.props.onClick({ name: 'date', type: 'date' });
  };

  render() {
    const years = range(2020, getYear(new Date()) + 1, 1);
    const months = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'May',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ];

    const DateInput = ({ value, onClick }) => (
      <input
        type="text"
        value={value}
        onClick={onClick}
        className="mb-4 h-2r tag tag--primary"
      />
    );

    return (
      <div className="datepicker">
        <DatePicker
          showIcon
          dateFormat="dd/MM/yyyy"
          icon="fa fa-calendar mr-4"
          renderCustomHeader={({ date,
                                 changeYear,
                                 changeMonth,
                                 decreaseMonth,
                                 increaseMonth,
                                 prevMonthButtonDisabled,
                                 nextMonthButtonDisabled
                              }) => (
                                      <div className= "d-flex justify-content-center">
                                        <button className="btn btn-light btn-sm mx-2"
                                                onClick={decreaseMonth}
                                                disabled={prevMonthButtonDisabled}>
                                          {'<'}
                                        </button>
                                        <select className="form-select-sm mr-1"
                                                value={getYear(date)}
                                                onChange={({ target: { value } }) => changeYear(value)}>
                                          {years.map((option) => (
                                            <option key={option} value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </select>

                                        <select className="form-select-sm"
                                                value={months[getMonth(date)]}
                                                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                          {months.map((option) => (
                                            <option key={option} value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </select>

                                        <button className="btn btn-light btn-sm mx-2"
                                                onClick={increaseMonth}
                                                disabled={nextMonthButtonDisabled}>
                                          {'>'}
                                        </button>
                                      </div>
                              )}
          selected={this.state.selectedDate}
          onChange={(date) => this.setSelectedDate(date)}
          calendarClassName='kyoga-calendar'
          locale='fr'
          // withPortal (to show calendar as a pop up)
          customInput={<DateInput/>}
        />
      </div>
    );
  }
}

export default DateFilter;
