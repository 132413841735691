import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-video-favorites"
export default class extends Controller {
  static values = {
    videoId: Number
  }

  static targets = ['addToFavorites', 'removeFromFavorites']

  toggleFavorite() {
    fetch(`/toggle_favorite_video/${this.videoIdValue}`, {
      headers: {'Accept': 'text/plain'}
    })
      .then(response => response.text())
      .then(() => this.checkStatus())
  }

  checkStatus() {
    const addToFavoritesTag = `<div  class="d-flex align-items-center text-jazzberry mb-3"
                                    data-toggle-video-favorites-target="addToFavorites"
                                    type="button"
                                    data-action="click->toggle-video-favorites#toggleFavorite">
                                <i  class="fa-regular fa-heart fa-xl"></i>
                                <p  class="mx-2">Ajouter la vidéo à mes favoris</p>
                              </div>`

    const removeFromFavoritesTag = `<div  class="d-flex align-items-center text-jazzberry mb-3"
                                          data-toggle-video-favorites-target="removeFromFavorites"
                                          type="button"
                                          data-action="click->toggle-video-favorites#toggleFavorite">
                                      <i  class="fa-solid fa-heart fa-xl"></i>
                                      <p class="mx-2">Retirer la vidéo de mes favoris</p>
                                    </div>`

    if (this.hasAddToFavoritesTarget) {
      this.element.innerHTML = removeFromFavoritesTag
    } else {
      this.element.innerHTML = addToFavoritesTag
    }
  }
}
