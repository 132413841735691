import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper';

export default class extends Controller {
  connect() {
    const swiper = new Swiper(".mySwiper", {
      effect: "coverflow",
      grabCursor: true,
      // centeredSlides: true,
      slidesPerView: "auto",
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 300,
        modifier: 1,
        slideShadows: false,
      }
    });
  }
}
